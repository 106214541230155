<template>
  <div class="home container">
    
    <h1>Password Generator</h1>
    <div class="flex spaceBetween">
      <div class="form container_input col">
        <h2>Requête</h2>
        <div class="fillWidth flex middleAlign">
            <label class="label" for="nbLoop">Nb Char</label>
            <input class="the_input_text input_purple" type="number" name="nbLoop" id="nbLoop" v-model="nbLoop">
        </div>
        <div class="fillWidth flex middleAlign">
          <label class="label" for="withSChar">Special Char </label>
          <input type="checkbox" name="withSChar" id="withSChar" v-model="withSChar">
        </div>
        <div class="fillWidth flex middleAlign">
            <label class="label" for="nbLoop">Nb occurence </label>
            <input class="the_input_text input_purple" type="number" name="nbLoop" id="nbLoop" v-model="nbOcc">
        </div>
        <div class="flex container_btn spaceBetween">
          <button class="the_btn b_accent2" @click="generatePassword(nbLoop, withSChar, nbOcc)">GENERER</button>
        </div>
      </div>
</div>

        
    
    
    
    <div class="container_resultat">
      <h2>Résultat</h2>
      <pre class="prettyprint">
        <div v-for="(code, i) in getData" :key="i" @click="CopyClipboard(code)" class="password">
          {{ code }}
        </div>
      </pre>
    </div>
  </div>
</template>

<script>

	export default {
	metaInfo: {
	meta: [
	{
	vmid: "description",
	name: "description",
	content:
	"Graphibox à Gimont dans le Gers "
	},
	{
	vmid: "keywords",
	name: "keywords",
	content:
	"Graphibox, Climatisation, Gimont, Gers"
	}
	]
	},
	name: 'GeneratorPassword',
	data() {
	return {
	withSChar: false,
  nbLoop: 8,
  getData: [],
  nbOcc: 1
	}
	},
	mounted() {
  },
  methods: {
    CopyClipboard(string) {
      if (string != null && string != '') {
        this.showMessage = true;
        navigator.clipboard.writeText(string);
        console.log("Copy in ClipBoard : " + string);
      }
    },
	generatePassword(nb, withSpecialChar, nbOccurrence) {
	if (nb == '') {nb = 8;}
	var length = nb;
	let charset = "abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
	if (withSpecialChar) {
	charset += "oO0";
	charset += "&é(-è_çà)=$^*ù!:;,?./§}]@^\`|[{#";
	}

	
  for(var j = 0; j < nbOccurrence; j++) {
    let output = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        output += charset.charAt(Math.floor(Math.random() * n));
    }
    this.getData.push(output);
    
  }
},
  },
}
</script>

<style>
h2 {
  font-size: 1.3rem;
}
.fillWidth {
  width: 100%;
}
label.label {
  padding: 10px 16px;
}
.label {
  width: 150px;
  margin-right: 2rem;
}
.col {
  width: 45%;
}
.column {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.input_purple {
  width: calc(100% - 220px - 2rem);
}
.column1 {
  width: calc(182px + 2rem);
}
.column2 {
  width: calc(100% - 182px - 2rem);
}
.container_btn {
  margin-top: 2rem;
}
.container_URL {
  word-wrap: break-word;
}
.container_URL .content_URL {
  text-decoration: underline;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.container_URL .content_URL:hover {
  color: var(--accent-base);
}
.container_resultat {
  margin-top: 2rem;
  border: 1px dashed var(--secondary-base);
  padding: 1rem;
}
.container_resultat pre.prettyprint {
  border: transparent;
}
.password {
  cursor: pointer;
}
</style>